<template>
  <div style="height: 100%;">

    <l-map ref="map" class="map" id="theMap" :zoom="karte.zoom" :center="mapCenter" :options="karte.mapOptions">

      <l-tile-layer
      :name="karte.selectedTile.name"
      :url="karte.selectedTile.url"
      :attribution="karte.selectedTile.attribution"
      layer-type="base"
      />

      <l-marker v-if="markerData!=null" :lat-lng="markerData.latLng" :icon="getSymbolIcon(content)">
      </l-marker>

    </l-map>
  </div>
</template>

<script>
  import 'leaflet/dist/leaflet.css';
  import { latLng, divIcon } from 'leaflet';
  import { getMarkerIcon } from '@/utils/lupe-helpers';
  import { LMap, LTileLayer, LMarker, LPopup } from "vue2-leaflet";

  export default {
    name: 'SingleMapView',
    components: {
      LTileLayer,
      LMarker,
      LPopup,
      LMap,
    },
    props: {
      height: {
        type: String,
        default: '700px',
      },
      content: {
        type: Object,
        required: true,
        default: null,
      },
      addressObj: {
        type: Object,
        default: null,
      },
    },
    data() {
      return {
        karte: {
          modus: false,
          zoom: 17,
          center: latLng(52.419191,10.768606),
          mapOptions: {
            zoomSnap: 0.5,
            zoomControl: true,
            touchZoom: true,
            dragging: true,
            doubleClickZoom: true,
            scrollWheelZoom: true,
            keyboard: true,
          },
          clusterOptions: {
            spiderfyOnMaxZoom: true,
            showCoverageOnHover: false,
          },
          selectedTile: {
           url:"https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png",
           attribution:"&copy; <a href=\"https://www.openstreetmap.org/copyright\">OpenStreetMap</a> contributors &copy; <a href=\"https://carto.com/attributions\">CARTO</a>"
         },
       },
     };
   },
   computed: {
    markerData(){
      if(this.addressObj!=null && Object.prototype.hasOwnProperty.call(this.addressObj,'latitude') && this.addressObj.latitude!=null && Object.prototype.hasOwnProperty.call(this.addressObj,'longitude')){

        var mapMarker = {lat:this.addressObj.latitude, long:this.addressObj.longitude, street:this.addressObj.street, zipcode:this.addressObj.zipcode, city:this.addressObj.city, latLng:latLng(this.addressObj.latitude,this.addressObj.longitude)}; 

        return mapMarker;
      }
      return null;
    },
    mapCenter(){
      if(this.markerData!=null){
        return this.markerData.latLng;
      }
      return latLng(54.3232927, 10.1227652);
    },
    icon(){
      if(this.addressObj!==null && Object.prototype.hasOwnProperty.call(this.addressObj,'category') && this.addressObj.category!==null){
        switch(this.addressObj.category){
          case "Ausbildung & Weiterbildung":
          return '/assets/icons/beruf.png';
          break;
          case "Beratung & Hilfe":
          return '/assets/icons/hilfe.png';
          break;
          case "Freizeit":
          return '/assets/icons/freizeit.png';
          break;
          case "Kita":
          return '/assets/icons/kita-finder.png';
          break;
          case "Schule":
          return '/assets/icons/schul-finder.png';
          break;
        }
      }
      return "/assets/icons/kita-finder.png"; 
    }
  },
  mounted() {
  },
  methods: {
    getMarkerIcon,
    getSymbolIcon(content){
      var icon = getMarkerIcon(content);
      return divIcon(icon);
    },
  },
};
</script>
<style lang="scss">
  @import '@/scss/markers.scss';

  .leaflet-container .leaflet-marker-pane img{
    -webkit-filter: drop-shadow( 3px 3px 4px rgba(0, 0, 0, .5));
    filter: drop-shadow( 3px 3px 4px rgba(0, 0, 0, .5));
  }

</style>

